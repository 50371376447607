<style lang="less" scoped>
  .wap-doc-detail {
    padding: 10px;
    box-sizing: border-box;
  }
  .block {
    background-color: #FFF;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    & + .block {
      margin-top: 10px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  td {
    border: 1px solid #999;
    padding: 5px;
  }
  .tabs {
    margin-bottom: 10px;
    display: flex;
    .tab {
      padding: 2px 5px;
      color: #657180;
      transition: all .3s;
      border-bottom: 1px solid transparent;
      &.active {
        color: #000;
        border-bottom-color: #999;
      }
    }
  }
</style>

<template>
  <div class="wap-doc-detail">
    <div class="block">
      <fm-title title-text="公文详细"></fm-title>
      <table>
        <tr>
          <td style="white-space: nowrap;">收文编码</td>
          <td>{{docData.getCode}}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">收文日期</td>
          <td>{{docData.getTime ? docData.getTime.slice(0, 10) : ''}}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">来文单位</td>
          <td>{{docData.sendOrgName}}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">来文号</td>
          <td>{{docData.sendCode}}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">标题</td>
          <td>{{docData.title}}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">份数</td>
          <td>{{docData.num}}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">来文日期</td>
          <td>{{docData.sendTime ? docData.sendTime.slice(0, 10) : ''}}</td>
        </tr>
        <tr v-for="step in stepList" :key="step.id">
          <td>{{step.type}}</td>
          <td>
            <div v-html="getHtml(step.handles || [])"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="block">
      <fm-title title-text="文件"></fm-title>
      <Files readonly :fileIds="fileIds" :dataFileParm="{ fileIds }" />
    </div>
    <div class="block">
      <fm-title title-text="编辑内容"></fm-title>
      <div class="tabs">
        <span @click="chooseHandle = item" class="tab" :class="{
          'active': item.id === (chooseHandle ? chooseHandle.id : null)
        }" v-for="item in handleList" :key="item.id">
          {{item.officialDocumentHandleStepType}}
        </span>
      </div>
      <template v-if="chooseHandle">
        <fm-input-new v-model="chooseHandle.content" type="textarea" placeholder="" style="width: 100%;" />
        <div style="margin-top: 10px;display: flex;">
          <fm-btn @click="submit" long>提交</fm-btn>
          <fm-btn @click="$router.push({
            name: 'wap.doc.step', query: { id: id }
          })" long>安排人员</fm-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  officialDocumentRequest,
  officialDocumentHandleRequest
} from '@/api'

import Files from '@/views/wap/worker/fileUpload'

export default {
  components: { Files },
  data () {
    return {
      docData: {},
      fileIds: [],
      stepList: [],
      handleList: [],
      chooseHandle: null
    }
  },
  activated () {
    if (!this.$route.query.id) {
      this.$router.back()
    } else {
      this.loadMy()
      this.loadData()
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    isHandle () {
      return this.$route.query.handle
    }
  },
  methods: {
    async submit () {
      await officialDocumentHandleRequest.update(this.chooseHandle.id, this.chooseHandle)
      this.loadMy()
      this.loadData()
    },
    getHtml (handles) {
      let text = ''
      handles.filter(v => v.status === 'over').forEach(v => {
        text += (v.content ? (v.content + ' ') : '') + v.workerName + ' ' + (v.handleTime ? v.handleTime.slice(0, 10) : '') + '<br>'
      })
      return text
    },
    async loadMy () {
      if (this.id && this.isHandle) {
        let datas = await officialDocumentHandleRequest.getMy({
          officialDocumentId: this.id
        })
        this.handleList = datas || []
        if (this.handleList.length > 0) {
          if (this.chooseHandle) {
            this.chooseHandle = this.handleList.find(v => v.id === this.chooseHandle.id)
          } else {
            this.chooseHandle = this.handleList[0]
          }
        }
      }
    },
    async loadData () {
      if (this.id) {
        let datas = await officialDocumentRequest.getDetail({
          officialDocumentId: this.id
        })
        if (datas.length > 0) {
          this.docData = datas[0]
          this.fileIds = this.docData.fileIds ? this.docData.fileIds.split(',').filter(v => v).map(v => Number(v)) : []
          this.stepList = this.docData.steps || []
        }
      }
    },
  },
  created () {
    this.$store.dispatch('loadAllWorkerList')
  }
}
</script>
